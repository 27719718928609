<script setup lang="ts">
import GenericError from '~/components/user-interface/generic-error.vue';
import Button from '~/components/design-system/button.vue';
import Card from '~/components/design-system/card.vue';
import { useGameStore } from '~/stores/games.store';
import { useSiteStore } from '~/stores/site.store';
import GenericLoader from '~/components/user-interface/generic-loader.vue';
import { useIntersectionObserver } from '@vueuse/core';

enum CATEGORIES {
  FAVORITES = 'favorites',
  RECENTLY_PLAYED = 'recentlyPlayed',
  ALL = 'all',
}

interface IProps {
  category: string;
  inModal: boolean;
}
const games = useGameStore();
const route = useRoute();
const router = useRouter();
const image = useImagePath;
const settings = useSiteStore();

const props = withDefaults(defineProps<IProps>(), {
  category: '',
  inModal: false,
});

const gameCard = ref();
const displayLoader = ref(false);
const stopLoading = ref(false);
// const filteredGames: IFilteredGames = reactive({ games: [] });
const category = games.returnFullCategory(String(props.category));

const getCurrentVertical = computed(() =>
  settings.getSitemapMap.get(`/${route.params.contentType}`)
);

const filteredGames = computed(() => {
  if (props.category === CATEGORIES.FAVORITES) {
    return games.returnFavorites;
  }
  if (props.category === CATEGORIES.RECENTLY_PLAYED)
    return games.returnRecentlyPlayed;
  if (props.category === CATEGORIES.ALL)
    return games.returnCategory({
      category: CATEGORIES.ALL,
      provider: '',
      theme: '',
    });

  return games
    .returnCategory({
      category: props?.category,
      provider: route?.query?.provider,
      theme: route?.query?.theme,
    })
    .splice(0, category?.totalGames);
});

watch(
  () => filteredGames.value?.length,
  (value, oldValue, onCleanup) => {
    if (!value || !gameCard.value?.length) return;
    const lastCard = gameCard.value[gameCard.value?.length - 1];
    if (stopLoading.value) return;
    nextTick(() => {
      const { stop } = useIntersectionObserver(
        lastCard,
        async ([{ isIntersecting }]) => {
          if (
            isIntersecting &&
            category?.totalGames > filteredGames.value?.length &&
            props.category !== CATEGORIES.ALL
          ) {
            displayLoader.value = true;
            await useGetCategory(
              getCurrentVertical.value?.Mapping,
              String(props.category)
            ).then((data) => {
              if (data.length === 0) stopLoading.value = true;
              displayLoader.value = false;
            });
            stop();
          }
        },
        {
          threshold: 0.1,
        }
      );
    });
  },
  {
    deep: true,
    immediate: true,
  }
);
</script>

<template>
  <div class="container">
    <div
      v-if="filteredGames.length"
      class="grid-layout pb-5 gap-2"
      :class="
        inModal
          ? 'grid-cols-3 md:grid-cols-4'
          : 'grid-cols-3 md:grid-cols-4 lg:grid-cols-8 mt-2'
      "
    >
      <div v-for="(game, index) in filteredGames">
        <Card
          ref="gameCard"
          :key="`${game.name}-${index}`"
          :image="image(game.tileImage)"
          :title="game.name"
          :subtitle="game.provider"
          :badgeRight="game.badgeRight"
          :badgeLeft="game.badgeLeft"
          :id="game.id"
          :game="game"
          :favorite="game.favorite"
        />
      </div>

      <div v-if="displayLoader" class="relative">
        <GenericLoader container />
      </div>
    </div>
    <GenericError v-else state="info">
      <div
        class="flex align-items-center justify-content-between text-black dark:text-white text-left w-full"
      >
        <div class="mr-1">
          <div>{{ $t('no-results') }}</div>
          <div>{{ $t('no-results-message') }}</div>
        </div>
        <Button size="medium" @click="router.push('/')">
          {{ $t('reset-filters') }}
        </Button>
      </div>
    </GenericError>
  </div>
</template>
